<template>
    <div class="photograph-theme-box">
        <!-- 标题 -->
        <slot name="title"></slot>
        <!-- 列表 -->
        <el-row :gutter="20">
            <el-col 
                :span="8"
                v-for="item in themeData"
                :key="item.id">
                <div class="photograph-theme-item">
                    <!-- 图片 -->
                    <div class="photograph-theme-item-pic-box">
                        <el-image
                            :src="item.img"
                            fit="scale-down"
                            class="photograph-theme-item-pic"
                            lazy>
                            <!-- 加载中样式 -->
                            <template v-slot:placeholder>
                                <div class="image-slot">
                                    <i class="el-icon-loading"></i>
                                </div>
                            </template>
                            <!-- 加载失败样式 -->
                            <template v-slot:error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>
                    <!-- 信息 -->
                    <div class="photograph-theme-item-text">
                        <!-- 标题 -->
                        <router-link 
                            :to="{
                                name: 'photographDetail', 
                                query: {id: item.id}
                            }"
                            class="photograph-theme-item-title">
                            {{item.title+' / '+item.subtitle}}
                        </router-link>
                        <!-- 价格 -->
                        <div class="photograph-theme-item-price clearfix">
                            <span>{{'¥ '+item.price+' 元起'}}</span>
                            <router-link 
                                :to="{
                                    name: 'photographDetail', 
                                    query: {id: item.id}
                                }"
                                class="photograph-theme-item-buy">
                                立即购买
                                <i class="el-icon-d-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: [ "themeData" ],
}
</script>

<style scoped>
.photograph-theme-item{
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
}
.photograph-theme-item-pic-box{
    position: relative;
    width: 100%;
}
.photograph-theme-item-pic-box::before{
    display: block;
    content: "";
    padding-top: 100%;
}
/* 图片 */
.photograph-theme-item-pic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* 文字部分 */
.photograph-theme-item-text{
    padding: 15px;
}
/* 标题 */
.photograph-theme-item-title{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 17px;
    color: #303133;
    margin-bottom: 20px;
}
.photograph-theme-item-price>a:hover,
.photograph-theme-item-title:hover{
    color: #8d9afc;
}
/* 价格 */
.photograph-theme-item-price>span{
    float: left;
    color: #E6A23C
}
.photograph-theme-item-price>a{
    float: right;
    color: #E6A23C
}
</style>